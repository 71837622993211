<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.verifyTime') }}</h3>
    <div class="flix-form-group">
      <flixIcon id="info-sign" /> {{ $t('message.verifyTimeInfo') }}
    </div>
    <div class="flix-form-group">
      <select class="flix-form-control" v-model="verify" @change="setVerify">
        <option value="1">1 {{ $t('message.hour') }}</option>
        <option value="2">2 {{ $t('message.hours') }}</option>
        <option value="3">3 {{ $t('message.hours') }}</option>
        <option value="6">6 {{ $t('message.hours') }}</option>
        <option value="12">12 {{ $t('message.hours') }}</option>
        <option value="18">18 {{ $t('message.hours') }}</option>
        <option value="24">24 {{ $t('message.hours') }}</option>
        <option value="48">48 {{ $t('message.hours') }}</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: { data: Object, callback: Function },
  data () {
    return {
      verify: this.getVerifyTime()
    }
  },
  watch: {
  },
  methods: {
    getVerifyTime () {
      if (typeof this.data.verifyTime === 'undefined' || !this.data.verifyTime) {
        return 2
      }
      return this.data.verifyTime * 1
    },
    setVerify () {
      this.callback(this.verify)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
